import React from 'react'
import { FormErrorMessage } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Select,
	Input,
	InputRightElement,
} from '@chakra-ui/react'

const ControlledSelect = (props) => {
	const {
		defaultValue,
		control,
		name,
		label,
		placeholder,
		helperText,
		options,
		scrollToOnFocus,
		errors,
		required,
		children,
		onChange,
	} = props
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				// console.log("PROPS", isDirty, isTouched, props?.ref?.current?.value, props?.ref?.current?.value ? 'gray.200' : 'gray.900')
				return (
					<FormControl isInvalid={errors[name]} onChange={onChange}>
						{label && <FormLabel htmlFor={name}>{label}</FormLabel>}
						<Select
							id={name}
							placeholder={placeholder}
							color={props?.ref?.current?.value ? 'gray.900' : 'gray.400'}
							{...props}>
							{options}
						</Select>
						{errors[name] ? (
							<FormErrorMessage display="block">{errors[name].message}</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
					</FormControl>
				)
			}}
		/>
	)
}

export default ControlledSelect
