import React from 'react'
import { Controller } from 'react-hook-form'
import { Input } from '@chakra-ui/react'

const ControlledInputHidden = (props) => {
	const { control, name, defaultValue } = props

	return (
		<Controller
			id={name}
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				return <Input type="hidden" id={name} {...props} />
			}}
		/>
	)
}

export default ControlledInputHidden
