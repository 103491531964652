import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { chakra, SimpleGrid, Divider, FormLabel, Grid, Text, Flex, VStack } from '@chakra-ui/react'
import { showYearsFieldFor, showOtherFieldFor } from '../../schema'
import Input from '../../../../molecules/controls/Input'
import Select from '../../../../molecules/controls/Select'
import Date from '../../../../molecules/controls/Date'
import FieldArray from '../../../../molecules/controls/FieldArray'
import { deleteRecipientAndReloadEvent } from '../../../../../../api/app/events'
import { eventTypes } from '@vidday/data'
import dayjs from 'dayjs'
import Toggle from '../../../../atoms/Toggle'

const filteredEventTypes = eventTypes.filter((o) => o.public && o.webApp)

const Form = ({ event, recipients, setHasChanged }) => {
	const dispatch = useDispatch()
	const location = useLocation()

	const { occasion, occasionOther, years, deadline, guestbook } = event
	const { control, errors, watch, setValue, register } = useFormContext()
	const { fields, append, remove, insert, update } = useFieldArray({
		control,
		name: 'recipients',
		keyName: 'key',
		defaultValue: [{ firstName: '', lastName: '' }],
	})

	/** Watch values from the form */
	const values = watch()

	/* ******************************
	 * Should we show Year and OccasionOther fields ?
	 * **************************** */
	const [showYearField, setShowYearField] = useState(false)
	const [showOtherField, setShowOtherField] = useState(false)
	const [friendlyDate, setFriendlyDate] = useState('')
	const [toggle, setToggle] = useState(guestbook)

	/* ******************************
	 * SIDE EFFECTS
	 * Prepopulate form values for this step,
	 * based on existing values
	 * **************************** */

	useEffect(() => setValue('occasion', occasion), [occasion])
	useEffect(() => setValue('guestbook', guestbook), [guestbook])
	useEffect(() => setValue('occasionOther', occasionOther), [occasionOther, showOtherField])
	useEffect(() => {
		if (showYearField) setValue('years', years)
	}, [years, showYearField])
	useEffect(() => {
		if (deadline) {
			setValue('deadline', deadline)
		} else {
			setValue('deadline', null)
		}
	}, [deadline])

	useEffect(() => {
		if (values?.deadline) {
			setFriendlyDate(dayjs(values.deadline).format('MMMM D YYYY'))
		} else {
			setFriendlyDate(null)
		}
		setHasChanged(true)
	}, [values.deadline])

	useEffect(() => {
		recipients && recipients.length > 0 && setValue('recipients', recipients)
	}, [recipients])

	// Toggle Guestbook
	useEffect(() => {
		setValue('guestbook', toggle)
	}, [toggle])
	const handleToggle = () => {
		setToggle(!toggle)
	}

	/** Determine which additional fields need to be registered.  */
	useEffect(() => {
		/** Should we display aditional fields ? */
		setShowYearField(showYearsFieldFor.includes(values.occasion))
		setShowOtherField(showOtherFieldFor.includes(values.occasion))

		if (showYearsFieldFor.includes(values.occasion)) {
			register('years')
		}
		if (showOtherFieldFor.includes(values.occasion)) {
			register('occasionOther')
		}
		if (values.occasion == 'wedding') {
			register('guestbook')
		}
	}, [values.occasion, register])

	useEffect(() => {
		var formRecipientsChange = () => {
			let difference = false
			if (recipients?.length !== values?.recipients?.length) {
				difference = true
			} else {
				recipients.forEach((r, i) => {
					if (
						(r.firstName && r.firstName != values?.recipients[i].firstName) ||
						(r.lastName && r.lastName != values?.recipients[i].lastName)
					) {
						difference = true
					}
				})
			}
			return difference
		}

		var changed =
			formRecipientsChange() ||
			occasion != values.occasion ||
			(occasionOther ? occasionOther != values.occasionOther : false) ||
			deadline != values.deadline ||
			years != values.years ||
			guestbook != values.guestbook

		setHasChanged(changed)
	}, [values])

	/* ****************************
	 * DEFINE METHODS
	 * ************************** */

	const deleteRecipient = (index) => {
		// Check if this recipient was existing initially or if we created a new one
		let currentRecipient = values.recipients[index]

		let didExistBefore = recipients.findIndex((el) => el.id === currentRecipient.id)

		/** Remove recipient from current the remote if it was already registered */
		if (currentRecipient && didExistBefore != -1) {
			dispatch(deleteRecipientAndReloadEvent(currentRecipient.id))
		}
		/** Remove recipient from the current field array */
		remove(index)
	}

	return (
		<chakra.form w="full">
			<SimpleGrid columns={values.occasion == 'wedding' ? [1, 2] : [1, 1]} spacing="1rem" w="full" mb="1rem">
				<Select
					control={control}
					errors={errors}
					name="occasion"
					placeholder="Select an occasion"
					label="Occasion"
					defaultValue={values.occasion || null}
					options={filteredEventTypes.map((el, i) => (
						<option key={i} value={el.type}>
							{el.label}
						</option>
					))}
				/>
				{values.occasion == 'wedding' && (
					<VStack direction="col" pb=".875rem" alignItems="left" justify="space-between">
						<Text me="1rem" fontWeight={'bold'}>
							This is a Guestbook
						</Text>
						<Toggle value={values.guestbook} name="guestbook" onToggle={handleToggle} />
					</VStack>
				)}
			</SimpleGrid>
			<Divider variant="dashed" my="1rem" borderColor="gray.300" />

			<SimpleGrid columns={[1, showOtherField || showYearField ? 2 : 1]} spacing="1rem">
				<Date
					control={control}
					errors={errors}
					name="deadline"
					label="Due date"
					placeholder="Submissions are due by"
					autoOpen={location.state?.focusDate}
					saveLabel="Done"
					helperText="People can submit past this deadline"
					useTime
				/>

				{showOtherField && (
					<Input
						control={control}
						errors={errors}
						name="occasionOther"
						label="What's the special occasion?"
						defaultValue={occasionOther || null}
					/>
				)}

				{showYearField && (
					<Input
						type="number"
						max="150"
						min="1"
						control={control}
						errors={errors}
						name="years"
						label="How many years are they celebrating?"
						defaultValue={years || null}
					/>
				)}
			</SimpleGrid>

			<Divider variant="dashed" my="1rem" borderColor="gray.300" />

			<FormLabel>Recipients</FormLabel>
			<FieldArray
				control={control}
				errors={errors}
				name="recipients"
				remove={deleteRecipient}
				insert={insert}
				append={append}
				update={update}
				data={fields}
			/>
		</chakra.form>
	)
}

export default Form
