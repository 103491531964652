import React from 'react'
import { chakra, SimpleGrid, Box, Button, Flex, IconButton, FormLabel, HStack } from '@chakra-ui/react'
import {
	AddCircleRounded,
	AccountCircleRounded,
	ClearRounded,
	EmailRounded,
	LocalPhoneRounded,
} from '../../../atoms/Icon'
import Input from '../Input'
import InputHidden from '../InputHidden'
import SimpleEmail from '../InputEmail/SimpleEmail'
import Checkbox from '../Checkbox'
import PhoneNumber from '../PhoneNumber'
import { useFormContext } from 'react-hook-form'

const ControlledRecipientFieldArray = (props) => {
	const {
		control,
		collectEmail,
		confirmEmail,
		emailLabel,
		collectSms,
		confirmSms,
		smsLabel,
		allowMultiple = true,
		hideNames = false,
		name,
		label,
		placeholder,
		helperText,
		errors,
		data,
		values,
		append,
		remove,
		onBlur,
	} = props

	const { setError, clearErrors, register } = useFormContext()
	return (
		<>
			{data &&
				data.length > 0 &&
				data.map((item, index, col) => {
					return (
						<Box key={item.key} w="full">
							<Box w="full" pos="relative" mb={values?.viaSms ? '2rem' : null}>
								{(collectEmail || collectSms) && data.length > 1 && (
									<FormLabel>Info for {item.firstName}:</FormLabel>
								)}

								{item.id && (
									<InputHidden
										control={control}
										errors={errors}
										id={`recipients[${index}].id`}
										name={`recipients[${index}].id`}
										defaultValue={item.id}
										index={index}
									/>
								)}

								{!hideNames && (
									<SimpleGrid columns={[1, 2]} spacing="1rem" w="full" mb="1rem">
										<Input
											control={control}
											errors={errors}
											id={`recipients[${index}].firstName`}
											name={`recipients[${index}].firstName`}
											placeholder="Recipient first name"
											defaultValue={item.firstName || ''}
											index={index}
											rightElement={<AccountCircleRounded color="gray.300" />}
											onBlur={onBlur ? onBlur : false}
										/>
										<Box pos="relative" pr={index > 0 ? '2.4rem' : '0'}>
											<Input
												control={control}
												errors={errors}
												id={`recipients[${index}].lastName`}
												name={`recipients[${index}].lastName`}
												placeholder="Recipient last name"
												defaultValue={item.lastName || ''}
												index={index}
												rightElement={<AccountCircleRounded color="gray.300" />}
												onBlur={onBlur ? onBlur : false}
											/>
											{index > 0 && (
												<IconButton
													onClick={() => remove(index)}
													backgroundColor="gray.300"
													colorScheme="red"
													height="24px"
													width="24px"
													minW="24px"
													maxW="24px"
													borderRadius="full"
													pos="absolute"
													right="0"
													top="8px"
													aria-label="Remove Recipient"
													icon={<ClearRounded />}
												/>
											)}
										</Box>
									</SimpleGrid>
								)}
								{collectEmail && (
									<>
										{/* <FormLabel>Email for {item.firstName}:</FormLabel> */}
										<SimpleGrid columns={[1, 2]} spacing="1rem" w="full" mb="1rem">
											<SimpleEmail
												control={control}
												errors={errors}
												type="email"
												placeholder={`Email`}
												defaultValue={item.deliveryEmail || undefined}
												index={index}
												id={`recipients[${index}].deliveryEmail`}
												name={`recipients[${index}].deliveryEmail`}
											/>
											{confirmEmail && (
												<SimpleEmail
													control={control}
													errors={errors}
													type="email"
													placeholder={`Confirm email`}
													index={index}
													id={`recipients[${index}].confirmDeliveryEmail`}
													name={`recipients[${index}].confirmDeliveryEmail`}
												/>
											)}
										</SimpleGrid>
									</>
								)}
								{collectSms && (
									<>
										{/* <FormLabel>Mobile for {item.firstName}:</FormLabel> */}
										<SimpleGrid columns={[1, 2]} spacing="1rem" w="full" mb="1rem">
											<PhoneNumber
												control={control}
												// fabricate nested error
												errors={
													errors?.recipients && errors?.recipients[index]?.deliverySms
														? {
																[`recipients[${index}].deliverySms`]:
																	errors.recipients[index].deliverySms,
														  }
														: {}
												}
												name={`recipients[${index}].deliverySms`}
												label=""
												placeholder={`Mobile number`}
												setError={setError}
												clearErrors={clearErrors}
												register={register}
												addPrefix
											/>
											{collectSms && confirmSms && (
												<PhoneNumber
													control={control}
													// fabricate nested error
													errors={
														errors?.recipients &&
														errors?.recipients[index]?.confirmDeliverySms
															? {
																	[`recipients[${index}].confirmDeliverySms`]:
																		errors.recipients[index].confirmDeliverySms,
															  }
															: {}
													}
													name={`recipients[${index}].confirmDeliverySms`}
													label=""
													placeholder={`Confirm mobile number`}
													setError={setError}
													clearErrors={clearErrors}
													register={register}
													addPrefix
												/>
											)}
										</SimpleGrid>
									</>
								)}
							</Box>
						</Box>
					)
				})}
			{allowMultiple && (
				<Flex justify="center" w="full">
					<Button
						variant="ghost"
						leftIcon={<AddCircleRounded />}
						onClick={() => append({ firstName: '', lastName: '' })}>
						Another Recipient
					</Button>
				</Flex>
			)}
		</>
	)
}

export default ControlledRecipientFieldArray
