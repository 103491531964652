import React from 'react'
import { VStack, Text, Button, Heading, Image, HStack } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import { FormControl, Input, FormHelperText, FormErrorMessage } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schemaDelete = Yup.object().shape({
	deleteButton: Yup.string()
		.matches(/delete/, { message: "Please type 'delete' to delete this event." })
		.required(),
})

const DeleteVidDay = (props) => {
	const { onClose, onDelete } = props

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schemaDelete),
	})

	const onSubmit = (data) => {
		onDelete()
		onClose()
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image
							maxW="100px"
							src="/assets/images/graphics/vidday-delete-bomb.png"
							srcSet="/assets/images/graphics/vidday-delete-bomb@2x.png 2x, /assets/images/graphics/vidday-delete-bomb.png 1x"
							alt="Delete VidDay"
						/>
						<Heading as="h3" size="lg" variant="hero">
							Delete event page?
						</Heading>

						<Text>
							You are about to <strong>delete this event</strong> and you will not be able to recover it.
						</Text>

						<FormControl isInvalid={errors.deleteButton}>
							<Input id="deleteButton" type="text" name="deleteButton" ref={register} />
							{Object.keys(errors).length == 0 ? (
								<FormHelperText>Tap the word "delete" to confirm</FormHelperText>
							) : (
								<FormErrorMessage>
									{errors.deleteButton && errors.deleteButton.message}
								</FormErrorMessage>
							)}
						</FormControl>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							type="submit"
							// onClick={handleSubmit}
						>
							Yes, Delete
						</Button>
					</HStack>
				</ModalFooter>
			</form>
		</>
	)
}

const ModalDeleteEvent = (props) => {
	const { onClose, isOpen } = props

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<DeleteVidDay {...props} />
			</ModalContent>
		</Modal>
	)
}

export default ModalDeleteEvent
