import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import schemaEventDetail from './schema'
import SaveButton from '../../atoms/nav-controls/SaveButton'
import BackButton from '../../atoms/nav-controls/BackButton'
import NavBar from '../../atoms/NavBar'
import { HStack, Container, Box, Heading, Flex, Button, useDisclosure } from '@chakra-ui/react'
import Card from '../../atoms/Card'
import FooterBase from '../../molecules/FooterBase'
import DetailsForm from './src/Form'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { addRecipient, updateRecipient } from '../../../../api/app/events'
import { updateEvent } from '../../../../api/app/events'
import { history } from '../../../../history'
import ModalDeleteEvent from '../../organisms/ModalDeleteEvent'
import { archiveEvent } from '../../../../api/app/events'
import { DeleteRounded } from '../../atoms/Icon'
import dayjs from 'dayjs'
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)

const Controls = ({ onSave, hasChanged }) => {
	const { uuid } = useParams()

	return (
		<NavBar position="fixed">
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => history.push(`/event/${uuid}`)} />
			</HStack>
			<HStack spacing={['4%', '1rem']} justifyContent="flex-end">
				<SaveButton onClick={onSave} disabled={!hasChanged} />
			</HStack>
		</NavBar>
	)
}

const Save = ({ onSave, hasChanged }) => {
	return (
		<HStack spacing={['4%', '1rem']}>
			<Button variant="solid" size="sm" minW="100px" onClick={onSave} disabled={!hasChanged}>
				Save
			</Button>
		</HStack>
	)
}

const EventDetails = () => {
	const { event } = useEventContext()
	const dispatch = useDispatch()
	const { uuid } = useParams()
	const recipients = useEventRecipients()
	const deleteModal = useDisclosure()
	const navigate = useNavigate()
	const location = useLocation()

	const [hasChanged, setHasChanged] = useState(false)

	const handleDelete = () => {
		const eventData = { id: event.id, uuid: event.uuid, status: 'archived' }
		dispatch(archiveEvent(eventData))
	}

	/** Define methods and pass in initial state right away that will be used through the form context */
	const methods = useForm({
		defaultValues: {
			guestbook: event.guestbook,
			occasion: '',
			//occasionOther: '',
			deadline: '',
			//years: '',
			// recipients: [{ firstName: '', lastName: '', id: '' }],
		},
		resolver: yupResolver(schemaEventDetail),
	})
	/** This handle submit is passed down to the form and is executed through the onSubmit of the form itself (Sort of spaghetti....)  */
	const handleSubmit = (values) => {
		let data = {}

		// Do not insert key/values that have a null or '' values
		Object.keys(values).forEach((key) => {
			if (values[key] != '') {
				data[key] = values[key]
			}
		})
		// Booleans are handled differently
		data.guestbook = values.guestbook

		// Remove Event Media from object not needed for this call
		delete data.creator
		delete data.media
		delete data.invoice
		delete data.mediaSortingOrder // Don't overwrite the sorting order
		// Loop through recipients being submitted to see which one changed
		data.recipients.forEach(function (el) {
			// Check if that recipient already exist
			if (el.id && el.id !== null) {
				var currentRecipient = recipients.raw.find((r) => {
					return r.id == el.id ? r : null
				})

				//console.error(currentRecipient, "Current recipient found");
				if (currentRecipient !== null) {
					// Check if any of the values have changed
					if (el.firstName !== currentRecipient.firstName || el.lastName !== currentRecipient.lastName) {
						//console.info("our recipient has it's value changed, need to be saved.")
						dispatch(updateRecipient(el))
					}
				}
			} else {
				/** Delete id, being null. This is set server-side */
				delete el.id
				//console.info("about to create our new recipient!")
				// Link the new recipient to this current event
				el.eventId = event.id
				// Submit the new recipient as a new entry
				dispatch(addRecipient(el))
			}
		})

		// 'updateEvent() will send put request to update the event, and then dispatches loadEvent() to render state changes
		dispatch(updateEvent(data))

		/** Redirect to event root */
		//history.push(root)
		navigate(`/event/${uuid}`, {
			state: { backDestination: location.pathname },
		})
	}

	return (
		<FormProvider {...methods}>
			<Controls hasChanged={hasChanged} onSave={methods.handleSubmit((data) => handleSubmit(data))} />

			<Container maxW="container.md" py="1rem" variant="main">
				<Box my="2rem" textAlign="center" mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Edit details
					</Heading>
				</Box>

				<Card
					px={['1rem', '2rem']}
					py="2rem"
					alignItems="center"
					variant="main"
					mb="2rem"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					<DetailsForm event={event} recipients={recipients.raw} setHasChanged={setHasChanged} />
					<Flex pt="1rem" justify="center" w="full">
						<Save hasChanged={hasChanged} onSave={methods.handleSubmit((data) => handleSubmit(data))} />
					</Flex>
				</Card>

				<Flex justify="center" w="full">
					<Button
						variant="ghost"
						color="red"
						colorScheme="red"
						fontSize="xs"
						leftIcon={<DeleteRounded />}
						onClick={deleteModal.onOpen}>
						Delete this VidDay
					</Button>
				</Flex>

				<FooterBase />
			</Container>

			<ModalDeleteEvent isOpen={deleteModal.isOpen} onClose={deleteModal.onClose} onDelete={handleDelete} />
		</FormProvider>
	)
}

export default EventDetails
